<template>
  <!-- begin::TruckCategories edit -->
  <div>
    <div class="manager-title">
      <div>
        {{
          TruckCategoryID > 0
            ? $t("FLEET.CATEGORIES.TITLE_EDIT")
            : $t("FLEET.CATEGORIES.TITLE_ADD")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("FLEET.CATEGORIES.CAPACITY") }}
        </div>

        <div class="form-group d-flex align-items-center">
          <inline-svg
            class="svg-icon"
            src="/media/icons/help-alt-5-primary.svg"
          />
          <span
            class="pl-3"
            v-html="
              $t('FLEET.CATEGORIES.CATEGORY_INFO', {
                typeBasePosition: VehicleTypeBase.Position,
                typeBaseName: VehicleTypeBase.Name
              })
            "
          />
        </div>

        <div class="row">
          <div class="col-lg-12 form-group">
            <label>
              {{
                $t("FLEET.CATEGORIES.TOTAL_CAPACITY", {
                  typeBasePosition: VehicleTypeBase.Position
                })
              }}
            </label>
            <v-text-field
              class="form-control shortInput"
              v-model="Fields.Capacity"
              :placeholder="$t('FLEET.CATEGORIES.TOTAL_CAPACITY')"
              type="number"
              min="1"
              step="1"
              required
              :rules="[rules.required, rules.min1]"
              @input="padVolumeDiscount()"
            />
          </div>
        </div>

        <div class="manager-subtitle">
          {{ $t("FLEET.CATEGORIES.CAPACITY_PER_TYPE") }}
        </div>

        <v-simple-table
          class="white-table border-box shadow-box pt-0 mb-6 coefficentsTable"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <td>
                  &nbsp;
                </td>
                <td class="text-weight-bold">
                  <strong>{{ $t("FLEET.CATEGORIES.COEFFICIENT") }}</strong>
                </td>
                <td>
                  <strong>
                    {{ $t("FLEET.CATEGORIES.COMPUTED_CAPACITY") }}
                  </strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="vt in VehicleTypeList"
                :key="'Multiplier_' + vt.Position"
              >
                <td>
                  <strong>
                    {{ $t("FLEET.CATEGORIES.TYPE") + " " + vt.Position + ": " }}
                  </strong>
                  {{ vt.Name }}
                  <br />
                  <small>{{ vt.Description }}</small>
                  <br />
                  <small>
                    {{
                      $t("VEHICLE_TYPES.SIZE") +
                        ": " +
                        $t("VEHICLE_TYPES.LENGTH") +
                        " " +
                        vt.LengthMin +
                        " - " +
                        vt.LengthMax +
                        "; " +
                        $t("VEHICLE_TYPES.WIDTH") +
                        " " +
                        vt.WidthMin +
                        " - " +
                        vt.WidthMax +
                        "; " +
                        $t("VEHICLE_TYPES.HEIGHT") +
                        " " +
                        vt.HeightMin +
                        " - " +
                        vt.HeightMax
                    }}
                  </small>
                </td>
                <td>
                  <v-text-field
                    v-if="vt.Position < VehicleTypeBase.Position"
                    class="form-control shortInput"
                    v-model="Fields.CapacityMultiplier[vt.Position]"
                    type="number"
                    min="0"
                    max="1"
                    step="0.01"
                    required
                    :rules="[rules.requiredMin0, rules.max1]"
                  />
                  <v-text-field
                    v-if="vt.Position === VehicleTypeBase.Position"
                    class="form-control shortInput"
                    v-model="Fields.CapacityMultiplier[vt.Position]"
                    readonly
                  />
                  <v-text-field
                    v-if="vt.Position > VehicleTypeBase.Position"
                    class="form-control shortInput"
                    v-model="Fields.CapacityMultiplier[vt.Position]"
                    type="number"
                    min="1"
                    step="0.01"
                    required
                    :rules="[rules.required, rules.min1]"
                  />
                </td>
                <td>
                  <v-text-field
                    class="form-control shortInput"
                    v-model="CapacityMultiplied[vt.Position]"
                    readonly
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="row mb-0">
          <div class="col-lg-12 form-group mb-0">
            <v-checkbox v-model="Fields.IsDefault" style="margin-bottom: -1em">
              <template v-slot:label>
                <div class="pt-1 ml-3 text-dark font-weight-normal">
                  {{ $t("FLEET.CATEGORIES.DEFAULT_INFO") }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div v-if="!Fields.IsDefault" class="row align-items-center mb-9 pl-12">
          <v-text-field
            class="form-control shortInput"
            v-model="Fields.PercentIncreaseOverDefault"
            type="number"
            min="-100"
            max="99999.99"
            step="0.01"
            :rules="[rules.minMinus100, rules.maxAlmost100000]"
          />
          <span class="ml-6">
            {{ $t("FLEET.CATEGORIES.INCREASE_OVER_DEFAULT") }}
          </span>
        </div>

        <div class="manager-subtitle">
          {{ $t("FLEET.CATEGORIES.VOLUME_DISCOUNT") }}
        </div>

        <div class="form-group d-flex align-items-center">
          <inline-svg
            class="svg-icon"
            src="/media/icons/help-alt-5-primary.svg"
          />
          <span class="pl-3">
            {{
              $t("FLEET.CATEGORIES.VOLUME_DISCOUNT_INFO", {
                typeBasePosition: VehicleTypeBase.Position
              })
            }}
          </span>
        </div>

        <v-simple-table
          v-if="Fields.Capacity > 0"
          class="white-table border-box shadow-box pt-0 mb-6 coefficentsTable"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <td class="text-weight-bold">
                  <strong>
                    {{
                      $t("FLEET.CATEGORIES.VOLUME_DISCOUNT_HEADER_1", {
                        typeBasePosition: VehicleTypeBase.Position
                      })
                    }}
                  </strong>
                </td>
                <td>
                  <strong>
                    {{ $t("FLEET.CATEGORIES.VOLUME_DISCOUNT_HEADER_2") }}
                  </strong>
                </td>
                <td>
                  <strong>
                    {{ $t("FLEET.CATEGORIES.VOLUME_DISCOUNT_HEADER_3") }}
                  </strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="c in parseInt(Fields.Capacity)"
                :key="'Occupation_' + c"
              >
                <td>{{ Fields.Capacity - c + 1 }}</td>
                <td>{{ formatCapacityLabel(Fields.Capacity - c + 1) }}</td>
                <td>
                  <v-text-field
                    class="form-control shortInput"
                    v-model="Fields.VolumeDiscount[Fields.Capacity - c + 1]"
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    required
                    :rules="[rules.requiredMin0, rules.max100]"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/trucks/categories')"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::TruckCategories edit -->
</template>

<style scoped>
.coefficentsTable tr:hover {
  background: transparent;
}
.coefficentsTable td {
  border: 0 !important;
}
.coefficentsTable tbody td {
  padding-bottom: 1.75em !important;
}
.shortInput {
  max-width: 140px;
}
.svg-icon {
  width: 1.5em !important;
  height: 1.5em !important;
  min-width: 1.5em !important;
  min-height: 1.5em !important;
}
</style>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import TruckCategoryService from "@/core/services/api/v2/truckcategory.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "TruckCategoriesEdit",
  data() {
    return {
      TruckCategoryID: 0,
      VehicleTypeBase: {
        Position: "",
        Name: ""
      },
      Valid: true,
      Fields: {
        Capacity: 1,
        CapacityMultiplier: {},
        VolumeDiscount: { 1: 0 },
        IsDefault: false,
        PercentIncreaseOverDefault: 0
      },
      VehicleTypeList: [],
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        requiredMin0: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 0) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 });
          } else {
            return true;
          }
        },
        min1: value =>
          parseFloat(value) >= 1 ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: 1 }),
        minMinus100: value =>
          parseFloat(value) >= -100 ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: -100 }),
        max1: value =>
          parseFloat(value) <= 1 ||
          this.$i18n.t("VALIDATION.MAX_VALUE", { max: 1 }),
        max100: value =>
          parseFloat(value) <= 100 ||
          this.$i18n.t("VALIDATION.MAX_VALUE", { max: 100 }),
        maxAlmost100000: value =>
          parseFloat(value) < 100000 ||
          this.$i18n.t("VALIDATION.MAX_VALUE", { max: 99999.99 })
      }
    };
  },

  computed: {
    CapacityMultiplied: function() {
      let camu = {};
      this.VehicleTypeList.forEach(vt => {
        let multiplier = parseFloat(
          this.Fields.CapacityMultiplier[vt.Position]
        );
        if (isNaN(multiplier)) {
          multiplier = 1;
        }

        camu[vt.Position] = Math.floor(this.Fields.Capacity / multiplier);
      });

      return camu;
    }
  },

  mounted() {
    this.loadVehicleTypeList();
  },

  methods: {
    loadVehicleTypeList() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      VehicleTypeService.listAll().then(typesList => {
        this.VehicleTypeList = typesList;

        // Pick which one of the types is used as base for calculations
        // WE ASSUME THAT SUPERADMIN (under his word) WON'T DELETE VEHICLETYPES UNTIL THERE'S NO BASE !!!
        let baseFound = false;
        for (let t = 0; t < typesList.length; t++) {
          let maybeBase = typesList[t];
          if (maybeBase.IsBase) {
            this.VehicleTypeBase.Position = maybeBase.Position;
            this.VehicleTypeBase.Name = maybeBase.Name;
            baseFound = true;
            break;
          }
        }

        if (!baseFound) {
          // CAN'T DO ANY CALCULATION !!!
          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          this.$router.push("/manager/trucks/categories");
        } else {
          // Want to edit a TruckCategory (a certain "id") or create new?
          if (this.$route.params.id) {
            this.TruckCategoryID = parseInt(this.$route.params.id);
            this.loadTruckCategoryData();
          } else {
            this.TruckCategoryID = -1;

            // Set default multipliers for this new Category
            let multiply1 = [];
            typesList.forEach(vt => {
              multiply1[vt.Position] = 1;
            });
            this.Fields.CapacityMultiplier = Object.assign({}, multiply1);

            // And zero volume discounts
            this.padVolumeDiscount();

            // Remove page loader
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        }
      });
    },

    loadTruckCategoryData() {
      TruckCategoryService.getProfile(this.TruckCategoryID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/trucks/categories");
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Set multiplier=1 for base VehicleType
          this.Fields.CapacityMultiplier[this.VehicleTypeBase.Position] = 1;

          // And fill empty volume discounts with zeros
          this.padVolumeDiscount();

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    padVolumeDiscount() {
      for (let c = 1; c <= this.Fields.Capacity; c++) {
        if (!this.Fields.VolumeDiscount[c]) {
          this.Fields.VolumeDiscount[c] = 0;
        }
      }
    },

    formatCapacityLabel(n) {
      let percent = (n * 100) / this.Fields.Capacity;
      return percent.toFixed(1) + "% ";
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.TruckCategoryID > 0) {
          // Update TruckCategory
          TruckCategoryService.updateProfile(
            this.TruckCategoryID,
            this.Fields
          ).then(statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 409) {
                whyError = this.$i18n.t("FLEET.CATEGORIES.ERROR_409");
              } else if (statusCode === 422) {
                whyError = this.$i18n.t("FLEET.CATEGORIES.ERROR_422");
              } else if (statusCode === 424) {
                whyError = this.$i18n.t("FLEET.CATEGORIES.ERROR_424");
              } else if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        } else {
          // Create new TruckCategory: we get back its ID
          TruckCategoryService.createProfile(this.Fields).then(response => {
            if (response.TruckCategoryID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.TruckCategoryID = response.TruckCategoryID;
              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/trucks/categories/edit/" + this.TruckCategoryID
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("FLEET.CATEGORIES.ERROR_409");
              } else if (response === 422) {
                whyError = this.$i18n.t("FLEET.CATEGORIES.ERROR_422");
              } else if (response === 424) {
                whyError = this.$i18n.t("FLEET.CATEGORIES.ERROR_424");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
